span.stepper-value {
	margin:0;
	padding:0 15px;
	color: #434346;
}

.stepper-title {
	color: rgba(52,103,154,1);
	margin-bottom: 0.3rem;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0px;
}
.stepper-actions {
	border: 1px solid #9FA1A3;
	border-radius:5px;
}
.stepper-actions .stepper-right {
	border-radius: 0px 3px 3px 0;
}

.stepper-actions .stepper-left {
	border-radius: 3px 0px 0px 3px;
}
.timer-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.value {
    font-size: 30px;
}

.timer {
    font-family: "Montserrat", serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.slider-group-column {
	flex-grow: 1;

	border-top: 1px solid #DDDEDE; 
}


.slider-group-column + .slider-group-column {
	border-left: 1px solid #DDDEDE;

	/* remove double border  */
	margin-left:-1px;
}


.config-card {
	border-radius: 4px;
	border: 1px solid rgba(221, 222, 222, 1);
	background-color: rgba(255, 255, 255, 1);
	padding: 1.5rem 1rem;
	width: 250px;
	display: flex;
	position: relative;
	align-items: flex-start;
	text-align:left;
	color: #1575D5;
	margin-right: 10px;
	cursor: pointer;
	/* box-shadow: 0 1px 1px 0px rgba(20,23,28,.1); */
	transition: 0.15s;

}

.config-card.disabled {
	color: gray;
	cursor: not-allowed;
}

.config-card:hover {
	box-shadow: 0px 7px 15px -7px rgb(0 0 0 / 27%);
	/* box-shadow: 0 1px 4px 1px rgba(20,23,28,.15);  */
}

.config-card>svg {
	margin-right: 1rem;
}

.config-card p {
	color: var(--chakra-colors-gray-600);
	margin-top: 5px;
}

.config-card>.active-card {
	border-top: 4px solid;
	border-color: var(--chakra-colors-orange-500);
	border-radius: 4px 2px 8px 7px;
	inset: -1% 5% 100% 5%;
	position: absolute;


}

.page-actions {
	align-content: center;
	align-items: center;
}

.page-actions > * {
	margin: 0 10px;
}

.conversion-summary {
	padding: 1rem;
	font-weight:600;
	line-height: 18px;
	color:var(--chakra-colors-gray-600);
}

.conversion-summary .c-header {
	font-size: 18px;
	color:var(--chakra-colors-gray-700);
}
.conversion-summary .c-inner-content {
	border: 1px solid var(--chakra-colors-gray-300);
	border-radius:3px;
	font-size: 16px;

}

.conversion-summary .c-increment span {
	padding-left: 10px;
}

.conversion-summary .c-increment .divide {
	color:var(--chakra-colors-gray-400);
	font-weight: 500;
}
.conversion-summary .c-inner-content div:nth-child(-n + 2) {
	border-bottom: 1px solid var(--chakra-colors-gray-300);
}
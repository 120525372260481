.slider-group {
	padding:1rem;
	position:relative;
}
.slider-group > p {
	letter-spacing: 1.5px;
}

svg.slider-group-lock {
	position: absolute;
    right: 10px;
    top: 10px;
}
body,
html {
	background: #F6F7F8;
	overflow: hidden;
	height: 100vh;
}

#root {
	height: 100vh;
}

.wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	background: #F6F7F8;
	height: 100vh;
	overflow: scroll;
}

.main-content {
	border-radius: 4px;
	border: 1px solid rgba(221, 222, 222, 1);
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
}

.header {
	z-index: 1;
	overflow: hidden;
	padding: 1rem;
	position: fixed;
	/* Set the navbar to fixed position */
	top: 0;
	/* Position the navbar at the top of the page */
	width: 100%;
	/* Full width */
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);

	background-color: rgba(255, 255, 255, 1);

}

.header img {
	width: 150px;
}

.login-form {
	min-width: 350px;
}

.login-form label {
	color: var(--chakra-colors-blue-600);
}


.pass-checklist li {
	align-items: center;
	color: var(--chakra-colors-gray-600);
}

.pass-checklist svg {
	margin-right: 5px;
}

.custom-tooltip {
	padding: 1rem;
	background: white;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}

.custom-tooltip .label {
	color: var(--chakra-colors-blue-600);
	font-weight: 600;
}



.custom-tooltip .intro {
	font-weight: 600;
}

.conversions-table {
	height: 100%;
	margin-right: -30px;
	color: var(--chakra-colors-gray-600)
}

.conversions-table tr {
	vertical-align: bottom;
	border-bottom: 1px solid var(--chakra-colors-gray-300)
}

.conversions-table tbody td {
	padding: 16px 0;
}

.conversions-table th:last-child {
	text-align: right;
	padding-left: 10px;
}
.conversions-table td {
	font-weight: 600;
}
.conversions-table td:last-child {
	text-align: right;
	color: var(--chakra-colors-blue-600);
}

.conversions-table thead tr {
	line-height: 20px;
}

span.legend-text {
	color: var(--chakra-colors-gray-700);
	padding: 0 5px;
}
.pie-charts li {
	flex-grow: 1;
	border: 1px solid var(--chakra-colors-gray-300);
	border-top:none;
	border-right:none;
	padding: 25px 0;
}

.pie-charts li:nth-child(odd) {
	border-left: none;
}

span.pie-labels {
	color: black;
}

.rank-legend p {
	padding: 0 5px;
	font-weight:600;
	color: var(--chakra-colors-gray-400)
}
.rank-legend span {
	font-size: 15px;
	padding-right: 5px;
	color: var(--chakra-colors-gray-500)
}

.rank-legend svg {
	padding-right: 5px;
}

.rank-legend svg, .rank-legend p, .rank-legend span {
	vertical-align: middle;
}